import { useMutation } from 'react-query';

interface UploadToS3Variables {
  file: File;
  fileName: string;
  onUploadProgress?: (progressEvent: ProgressEvent) => void;
}

async function uploadFileToS3({
  file,
  fileName,
  onUploadProgress
}: UploadToS3Variables): Promise<string> {
  const url = `https://aws-website-bmcandrews-uploads.s3.us-east-2.amazonaws.com/${encodeURIComponent(
    fileName
  )}`;

  return new Promise<string>((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open('PUT', url, true);
    xhr.setRequestHeader('Content-Type', file.type);

    // Attach progress event if provided
    if (onUploadProgress) {
      xhr.upload.onprogress = onUploadProgress;
    }

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        console.log('File uploaded successfully:', url);
        resolve(fileName);
      } else {
        reject(new Error(`Failed to upload file: ${xhr.status} ${xhr.statusText}`));
      }
    };

    xhr.onerror = () => {
      reject(
        new Error(`Network error occurred while uploading file: ${xhr.status} ${xhr.statusText}`)
      );
    };

    xhr.send(file);
  });
}

export function useUploadToS3() {
  return useMutation<string, Error, UploadToS3Variables>(variables => uploadFileToS3(variables));
}
