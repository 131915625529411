import React, { useState } from 'react';
import { useUploadToS3 } from '../hooks/useUploadToS3';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

function UploadPage() {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const { mutate, data, isLoading, error, isSuccess } = useUploadToS3();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      setUploadProgress(0); // Reset progress when a new file is selected
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      mutate({
        file: selectedFile,
        fileName: selectedFile.name,
        onUploadProgress: progressEvent => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setUploadProgress(progress);
        }
      });
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column'
      }}
    >
      <input style={{ marginBottom: '20px' }} type='file' onChange={handleFileChange} />
      <button
        disabled={isLoading || !selectedFile}
        style={{ padding: '10px 20px', fontSize: '16px' }}
        onClick={handleUpload}
      >
        {isLoading ? 'Uploading...' : 'Upload'}
      </button>
      {isLoading && (
        <div style={{ width: '100%', maxWidth: '400px', marginTop: '20px' }}>
          <div style={{ backgroundColor: '#e0e0e0', borderRadius: '10px', overflow: 'hidden' }}>
            <div
              style={{
                width: `${uploadProgress}%`,
                backgroundColor: '#76c7c0',
                height: '20px',
                transition: 'width 0.2s ease-in-out'
              }}
            ></div>
          </div>
          <p style={{ marginTop: '5px' }}>{uploadProgress}%</p>
        </div>
      )}
      {error && <div style={{ color: 'red', marginTop: '10px' }}>Error: {error.message}</div>}
      {isSuccess && (
        <div style={{ color: 'green', marginTop: '10px' }}>File uploaded successfully: {data}</div>
      )}
    </div>
  );
}

export default function Upload() {
  return (
    <QueryClientProvider client={queryClient}>
      <UploadPage />
    </QueryClientProvider>
  );
}
